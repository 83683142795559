import { fetchAPI2 } from "../../fetchAPI2";
import { simulateApiError } from "../../errors";

export type OrderApproveLine = { ID: string; Approve: boolean };

export type OrderApprovePayload = OrderApproveLine[];

export const _sendOrderApproval = async (
  submission: OrderApproveLine | OrderApproveLine[]
) => {
  const payload = Array.isArray(submission) ? submission : [submission];

  // await simulateApiError({path: "/admin/orders/approve"});

  const data = await fetchAPI2<unknown, OrderApprovePayload>(
    "/admin/orders/approve",
    {
      method: "POST",
      body: payload,
    }
  );

  return data;
};
