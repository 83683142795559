import { action, json } from "@solidjs/router";
import { _sendOrderResubmission } from "../roma-api/admin/orders";
import { OrderResubmitLine } from "../roma-api/admin/orders";
import { getAdminOrders } from "./useAdminOrders";

export const submitOrderResubmissionAction = action(
  async (submission: OrderResubmitLine | OrderResubmitLine[]) => {
    const response = await _sendOrderResubmission(submission);

    // TODO - do we need revalidation? Manual revalidation fn on page this is used that revalidates based on the specific key (filters)
    return json(response, { revalidate: getAdminOrders.key });
  }
);
