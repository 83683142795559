import { getParamStringFromObject } from "~/utils/helpers";
import { AdminOrder, type OrderFilters, ORDERSORT, ORDERTYPE } from "./types";
import { ListResponse } from "../../types";
import { fetchAPI2 } from "../../fetchAPI2";
import { simulateApiError } from "../../errors";


type ListAdminOrdersResponse = ListResponse<AdminOrder>

export const _listAdminOrders = async (filters?: Partial<OrderFilters>) => {
  const defaultFilters: Partial<OrderFilters> = {
    limit: 50,
    sort: ORDERSORT.CREATED,
    to: new Date().toISOString().split("T")[0],
    // type: [
    //   ORDERTYPE.LENGTHRAILCHOP,
    //   ORDERTYPE.LENGTHBOX,
    //   ORDERTYPE.JOIN,
    //   ORDERTYPE.BUILTFRAME,
    //   ORDERTYPE.MIRROR,
    //   ORDERTYPE.GENERAL,
    //   ORDERTYPE.CORNERSAMPLE,
    //   ORDERTYPE.CORNERSAMPLECHARGED,
    //   ORDERTYPE.CUSTOM,
    // ],
  };

  const merged = { ...defaultFilters, ...filters };

  const params = getParamStringFromObject(merged);

  // await simulateApiError({path: `/admin/orders${params}`})

  const data = await fetchAPI2<ListAdminOrdersResponse>(`/admin/orders${params}`);

  return data;

};
