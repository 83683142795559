import { BaseLineItem } from "../../cart/types-lineItems";
import { SubItem } from "../../cart/types-productOrdering";

// TODO...

export enum ORDERSORT {
  CREATED = "created_at",
  ID = "order_id",
  SUBMITTED = "submitted_at",
  STATUS = "status",
  CUSTOMERNUMBER = "customer_number",
}

// TODO - Order type, recieved as these vals, or formatted strings?? confir
export enum ORDERTYPE {
  LENGTHRAILCHOP = "lengthRailChop",
  LENGTHBOX = "lengthBox",
  JOIN = "join",
  BUILTFRAME = "builtFrames",
  MIRROR = "mirrors",
  GENERAL = "general",
  CORNERSAMPLE = "cornerSamples",
  CORNERSAMPLECHARGED = "chargedCornerSamples",
  CUSTOM = "custom",
}


export enum ORDERSTATUS {
  COMPLETE = "complete",
  // AWAITINGAPPROVAL = "awaiting_approval", // ? This may be useless
  NEEDSAPPROVAL="needs_approval",
  PENDING = "pending",
  SUBMITTED = "submitted",
  REJECTED = "rejected",
  WAITING = "waiting",
}

export type OrderSortOption = `${ORDERSORT}`;
export type OrderTypeOption = `${ORDERTYPE}`;
export type OrderStatusOption = `${ORDERSTATUS}`;
export type OrderTypeLabel = typeof orderTypeLabels[keyof typeof orderTypeLabels];


export type OrderFilters = {
  limit: number;
  offset: number;
  from: string;
  to: string;
  search: string;
  sort: OrderSortOption;
  type: OrderTypeOption[];
  status: OrderStatusOption[];
  /**
   * This option should only be present for BPS (PERMISSION.BPSAPPROVEORDERS)
   */
  customers?: string[] | "all";
};

export interface AdminOrder {
  ID: string;
  CartID?: string;
  SAPOrderNum: string;
  CustomerNumber: string;
  AccountID: string;
  Status: OrderStatusOption;
  // OrderType: OrderTypeOption;
  OrderType: OrderTypeLabel;
  SubmittedAt: string;
  CreatedAt: string;
  BPSName: string;
  BPSNumber: string;
  ShipTo: string;
  ShipDetail: OrderAddress;
  BillTo: OrderAddress;
  Amount: number;
  Lines: OrderLineItem[];
}

export interface OrderLineItem extends BaseLineItem {
  SKU? : string; // when is SKU present on the top level of the line item instead of SubItem??
  AmountStr?: string;
  SubTotalStr?: string;
  DiscountStr?: string;
  CustomOrder?: boolean;
  SubItems: SubItem[]
}

export interface OrderAddress {
  Recipient: string;
  Number: string;
  Street: string;
  Street2: string;
  City: string;
  Region: string;
  Country: string;
  PostalCode: string;
  Phone: string;
  Email: string;
}

// ! Labels
export const orderSortLabels: Record<OrderSortOption, string> = {
  [ORDERSORT.ID]: "Order ID",
  [ORDERSORT.CREATED]: "Date Created",
  [ORDERSORT.SUBMITTED]: "Date Submitted",
  [ORDERSORT.STATUS]: "Order Status",
  [ORDERSORT.CUSTOMERNUMBER]: "Customer Number",
};

export const orderTypeLabels = {
  [ORDERTYPE.LENGTHRAILCHOP]: "Length, Rail, Chop",
  [ORDERTYPE.LENGTHBOX]: "Length, Box",
  [ORDERTYPE.JOIN]: "Join",
  [ORDERTYPE.BUILTFRAME]: "Built Frames",
  [ORDERTYPE.MIRROR]: "Mirrors",
  [ORDERTYPE.GENERAL]: "General",
  [ORDERTYPE.CORNERSAMPLE]: "Corner Samples",
  [ORDERTYPE.CORNERSAMPLECHARGED]: "Charged Corner Samples",
  [ORDERTYPE.CUSTOM]: "Custom",
} as const;

export const orderStatusLabels: Record<OrderStatusOption, string> = {
  [ORDERSTATUS.COMPLETE]: "Complete",
  [ORDERSTATUS.NEEDSAPPROVAL]: "Awaiting Approval",
  // [ORDERSTATUS.AWAITINGAPPROVAL]: "Awaiting Approval",
  [ORDERSTATUS.PENDING]: "Pending",
  [ORDERSTATUS.SUBMITTED]: "Submitted",
  [ORDERSTATUS.REJECTED]: "Rejected",
  [ORDERSTATUS.WAITING] : "Waiting",
};
