import { action, json } from "@solidjs/router";
import { _sendOrderApproval } from "../roma-api/admin/orders";
import { OrderApproveLine } from "../roma-api/admin/orders";
import { getAdminOrders } from "./useAdminOrders";

export const submitOrderApproveAction = action(
  async (submission: OrderApproveLine | OrderApproveLine[]) => {
    const response = await _sendOrderApproval(submission);

    // TODO - do we need revalidation? Manual revalidation fn on page this is used that revalidates based on the specific key (filters)
    return json(response, { revalidate: getAdminOrders.key });
  }
);
