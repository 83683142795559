import {
  Show,
  createSignal,
  Suspense,
  For,
  Component,
  createMemo,
  ErrorBoundary,
  createEffect,
} from "solid-js";
import {
  useSearchParams,
  revalidate,
  useAction,
  useSubmission,
} from "@solidjs/router";
import {
  getAdminOrders,
  submitOrderApproveAction,
  submitOrderResubmissionAction,
  useAdminOrders,
} from "~/services/admin";
import type {
  AdminOrder,
  OrderFilters,
} from "~/services/roma-api/admin/orders";
import { useErrorContext, useSessionContext } from "~/utils/contexts";
import {
  ORDERTYPE,
  ORDERSORT,
  ORDERSTATUS,
} from "~/services/roma-api/admin/orders";
import { AccountPanel } from "~/components/account";
import { createTimeAgo } from "@solid-primitives/date";
import { createPolled } from "@solid-primitives/timer";
import { LineImage, LineAttributes } from "~/components/ordering/checkout";
import { debounce } from "@solid-primitives/scheduled";
import { CustomerSearch } from "~/components/account/admin";
import { type ComboOption } from "~/components/inputs";
import {
  Input,
  SelectBox,
  TextFieldInput,
  Checkbox,
  MultiSelect,
} from "~/components/inputs";
import { usePrompt, BaseSkeleton } from "~/components/utility";
import { Icon } from "solid-heroicons";
import {
  chevronRight,
  chevronLeft,
  arrowPathRoundedSquare,
  archiveBoxXMark,
  archiveBoxArrowDown,
  documentCheck,
  chevronDown,
  xMark,
} from "solid-heroicons/outline";
import { checkError } from "~/services/roma-api/errors";
import { GenericError } from "~/components/utility/errors";
import { AuthBarrier } from "~/components/utility/AuthBarrier";

export default function OrderApproval() {
  const { permission } = useSessionContext();
  const { addError } = useErrorContext();
  const [filters, setFilters] = createSignal<Partial<OrderFilters>>({
    limit: 50,
    offset: 0,
    to: new Date().toISOString().split("T")[0],
    sort: ORDERSORT.CREATED,
    status: [],
    type: [
      ORDERTYPE.LENGTHRAILCHOP,
      ORDERTYPE.LENGTHBOX,
      ORDERTYPE.JOIN,
      ORDERTYPE.BUILTFRAME,
      ORDERTYPE.MIRROR,
      ORDERTYPE.GENERAL,
      ORDERTYPE.CORNERSAMPLE,
      ORDERTYPE.CORNERSAMPLECHARGED,
      ORDERTYPE.CUSTOM,
    ],
    ...(permission.BPSAPPROVEORDERS ? { customers: "all" } : {}),
  });
  const [Prompt, openPrompt] = usePrompt();
  const [viewDetail, setViewDetail] = createSignal();
  const [enableAutoUpdate, setEnableAutoUpdate] = createSignal(false);
  const [lastUpdated, setLastUpdated] = createSignal(new Date());

  const orders = useAdminOrders(filters);

  // * ACTIONS * //

  const approveOrRejectOrder = useAction(submitOrderApproveAction);
  const resubmitOrder = useAction(submitOrderResubmissionAction);

  const isApprovingOrRejecting = useSubmission(submitOrderApproveAction);
  const isResubmitting = useSubmission(submitOrderResubmissionAction);

  const orderActionIsPending = () =>
    !!isApprovingOrRejecting.pending || !!isResubmitting.pending;

  // * APPROVAL/SUBMISSION FNS * //
  const handleOrderApprove = async (orderID: string, isApproved: boolean) => {
    const ok = await openPrompt({
      title: `Confirm Order ${isApproved ? "Approval" : "Rejection"}`,
      description: `Are you sure you would like to ${
        isApproved ? "approve" : "reject"
      } order '${orderID}'? `,
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Orders", value: false, priority: true },
        {
          label: isApproved ? "Approve" : "Reject",
          value: true,
          style: isApproved ? "green" : "red",
        },
      ],
    });

    if (ok) {
      try {
        console.log(
          `${isApproved ? "APPROVING" : "REJECTING"} ORDER: `,
          orderID
        );
        const response = await approveOrRejectOrder({
          ID: orderID,
          Approve: isApproved,
        });
        // TODO - what does the response look like?, do something with that data?
        revalidateOrders();
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[handleOrderApproveReject]: error caught: ", err);
        }
        // TODO - could await a sentry report? flag with the Sentry ID so tanya/BPS can ID it directly to us?
        addError(err, {
          severity: "warning",
          showDetails: true,
          autoDisappear: true,
          title: `Order ${
            isApproved ? "Approval" : "Rejection"
          } Error: '${orderID}'`,
          message: `An error occurred while ${
            isApproved ? "approving" : "rejecting"
          } order '${orderID}'. Please try again shortly, if the error persist contact the administrator.`,
        });
        revalidateOrders();
        // TODO Sentry - add report option to flag directly to dev team? Save Tanya an email?? :P
      }
    }
  };

  const handleOrderResubmit = async (orderID: string) => {
    const ok = await openPrompt({
      title: "Confirm Order Resubmission",
      description: `Are you sure you would like to resubmit order '${orderID}'? `,
      requireInteraction: true,
      options: [
        { label: "Cancel - Return to Orders", value: false, priority: true },
        {
          label: "Resubmit",
          value: true,
          style: "blue",
        },
      ],
    });

    if (ok) {
      try {
        console.log(`RESUBMITTING ORDER: `, orderID);
        const response = await resubmitOrder({ ID: orderID });
        revalidateOrders();
      } catch (error) {
        const err = checkError(error);
        if (import.meta.env.DEV) {
          console.log("[handleOrderApproveReject]: error caught: ", err);
        }
        // TODO - could await a sentry report? flag with the Sentry ID so tanya/BPS can ID it directly to us?
        addError(err, {
          severity: "warning",
          showDetails: true,
          autoDisappear: true,
          title: `Order Resubmission Error: '${orderID}'`,
          message: `An error occurred while resubmitting order '${orderID}'. Please try again shortly, if the error persist contact the administrator.`,
        });
        revalidateOrders();
        // TODO Sentry - add report option to flag directly to dev team? Save Tanya an email?? :P
      }
    }
  };

  // *  utils  * //
  const doSearch = debounce((searchValue: string) => {
    setFilters((prev) => ({ ...prev, search: searchValue }));
  }, 500);

  // TODO
  const revalidateOrders = () => {
    revalidate(getAdminOrders.keyFor(filters()));
  };

  function URLify(string: string) {
    // TODO - test without useless (?) escape chars
    // * original * //
    // const urls = string.match(
    //   /((((ftp|https?):\/\/)|(w{3}\.))[\-\w@:%_\+.~#?,&\/\/=]+)/g
    // );
    //* with escapes removed *//
    const urls = string.match(
      /((((ftp|https?):\/\/)|(w{3}\.))[-\w@:%_+.~#?,&//=]+)/g
    );
    if (urls) {
      urls.forEach(function (url) {
        string = string.replace(
          url,
          '<a target="_blank" class="text-roma-blue" href="' +
            url +
            '">' +
            url +
            "</a>"
        );
      });
    }
    return string.replace("(", "<br/>(");
  }

  const statusDictionary = new Proxy<Record<ORDERSTATUS, string[]>>(
    {
      complete: ["success-green", "Complete"],
      needs_approval: ["roma-blue", "Awaiting Approval"],
      pending: ["orange-500", "Pending"],
      submitted: ["roma-medium-grey", "Submitted"],
      rejected: ["red-500", "Rejected"],
      waiting: ["yellow-500", "Waiting"],
    },
    {
      get: (target, prop: string | symbol): string[] => {
        if (typeof prop === "string" && prop in target) {
          return target[prop as ORDERSTATUS];
        }

        return ["gray-400", prop.toString()];
      },
    }
  );

  const orderTypesDict: Record<string, string> = {
    "Length/Rail/Chop": "lengthRailChop",
    "Length/Box": "lengthBox",
    Join: "join",
    "Built Frames": "builtFrames",
    Mirrors: "mirrors",
    General: "general",
    "Corner Samples": "cornerSamples",
    "Charged Corner Samples": "chargedCornerSamples",
    Custom: "custom",
  };

  const orderTypesReverse = Object.entries(orderTypesDict).reduce(
    (memo, [key, val]) => {
      memo[val] = key;
      return memo;
    },
    {} as Record<string, string>
  );

  const displayDate = (dateString: string | number) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const displayTime = (dateString: number) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
    };
    return new Date(dateString).toLocaleTimeString("en-US", options);
  };

  const polledLastUpdate = createPolled(() => {
    if (enableAutoUpdate()) {
      refreshOrders();
    }
    return lastUpdated();
  }, 300000);

  const refreshOrders = () => {
    setLastUpdated(new Date());
    revalidateOrders();
  };

  // * COMPONTENTS * //
  const ActionButton: Component<{
    label: string;
    icon?: IconPath;
    onClick: () => void;
    class?: string;
    disabled?: boolean;
  }> = (props) => {
    return (
      <button
        type="button"
        class="border border-gray-300 bg-roma-grey rounded-md text-xs py-0.5 w-full px-2 active:translate-y-0.5 text-roma-medium-grey hover:text-roma-dark-grey inline-flex items-center justify-center my-0.5 group disabled:cursor-not-allowed disabled:text-gray-300"
        classList={{ [`${props.class}`]: !!props.class }}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <span class="px-1">{props.label}</span>
        <Show when={props.icon}>
          <Icon
            path={props.icon as IconPath}
            class="w-5 h-5 ml-auto text-gray-400 group-hover:text-roma-dark-grey"
          />
        </Show>
      </button>
    );
  };

  const OrderLine: Component<AdminOrder & { index: number }> = (props) => {
    const [timeAgo] = createTimeAgo(Date.parse(props.CreatedAt));

    return (
      <>
        <tr
          class="transition-colors duration-500 relative "
          classList={{ "bg-faint-blue border-b": viewDetail() == props.index }}
        >
          <td class="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6 ">
            {props.ID}
            <br />
            <span class="text-roma-medium-grey">{props.SAPOrderNum}</span>
            <dl class="font-normal lg:hidden">
              <dt class="sr-only">Name</dt>
              <dd class="mt-1 truncate text-gray-700">
                {props.BillTo.Recipient}
              </dd>
              <dt class="sr-only lg:hidden">Customer Number</dt>
              <dd class=" truncate text-gray-500 lg:hidden">
                {props.CustomerNumber}
              </dd>
              <dt class="sr-only lg:hidden">Status</dt>
              <dd class="mt-1 truncate text-gray-500 lg:hidden">
                {statusDictionary[props.Status][1]}
              </dd>
            </dl>
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell ">
            {props.BillTo.Recipient} <br />
            <span class="text-roma-medium-grey">{props.CustomerNumber}</span>
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell">
            {props.OrderType}
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell">
            <p>{displayDate(Date.parse(props.CreatedAt))}</p>
            <p class="text-xs text-roma-medium-grey">
              {displayTime(Date.parse(props.CreatedAt))}
            </p>
            <p class="text-xs text-roma-medium-grey">{timeAgo()}</p>
          </td>
          <td class="hidden px-3 py-4 text-sm text-gray-900 lg:table-cell group relative">
            <div
              class={`w-3 h-3 mx-auto rounded-full bg-${
                statusDictionary[props.Status][0]
              }`}
            />
            <span class="hidden group-hover:block bg-roma-grey absolute  p-2 right-0 mt-2  rounded-md">
              {statusDictionary[props.Status][1]}
            </span>
          </td>
          <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-4 flex flex-col items-end ">
            <ActionButton
              class={`${
                viewDetail() === props.index ? "!bg-white" : "!bg-faint-blue"
              }  mb-3`}
              label={viewDetail() === props.index ? "Collapse" : "Expand"}
              icon={viewDetail() === props.index ? xMark : chevronDown}
              onClick={() => {
                viewDetail() == props.index
                  ? setViewDetail(null)
                  : setViewDetail(props.index);
              }}
            />
            <Show when={props.Status == ORDERSTATUS.NEEDSAPPROVAL}>
              <ActionButton
                label="Approve"
                disabled={orderActionIsPending()}
                icon={documentCheck}
                onClick={async () => {
                  await handleOrderApprove(props.ID, true);
                }}
              />
            </Show>
            <Show when={props.Status == "pending"}>
              <ActionButton
                label="Resubmit"
                disabled={orderActionIsPending()}
                icon={archiveBoxArrowDown}
                onClick={async () => {
                  // console.log("RESUBMIT >> ", props.ID);
                  await handleOrderResubmit(props.ID);
                }}
              />
            </Show>
            <Show when={["needs_approval", "pending"].includes(props.Status)}>
              <ActionButton
                label="Reject"
                disabled={orderActionIsPending()}
                icon={archiveBoxXMark}
                onClick={async () => {
                  // console.log("REJECT >> ", props.ID);
                  await handleOrderApprove(props.ID, false);
                }}
              />
            </Show>
          </td>
        </tr>
        <tr class="border-none">
          <td colSpan="6">
            <div
              class="pl-4 pr-3 sm:pl-6 text-sm bg-white transition-all ease-linear duration-700"
              classList={{
                "max-h-0 overflow-hidden": viewDetail() !== props.index,
                "max-h-auto overflow-auto": viewDetail() == props.index,
              }}
            >
              <div
                class="py-4 transition-opacity duration-1000"
                classList={{
                  "opacity-0": viewDetail() !== props.index,
                  "opacity-1": viewDetail() == props.index,
                }}
              >
                <div class="py-4 px-4 mb-3 bg-roma-grey grid max-sm:child:py-3 sm:grid-cols-3 gap-2">
                  <div class="max-sm:border-b">
                    <p class="font-bold">Ship To:</p>
                    <p>{props.ShipDetail.Recipient}</p>
                    <p>
                      {props.ShipDetail.Number} {props.ShipDetail.Street}
                    </p>
                    <p>{props.ShipDetail.Street2}</p>
                    <p>
                      {props.ShipDetail.City}, {props.ShipDetail.Region}
                    </p>
                    <p>
                      {props.ShipDetail.Country}, {props.ShipDetail.PostalCode}
                    </p>
                    <Show when={props.ShipDetail.Phone}>
                      <p>Tel: {props.ShipDetail.Phone}</p>
                    </Show>
                    <Show when={props.ShipDetail.Email}>
                      <p>Email: {props.ShipDetail.Email}</p>
                    </Show>
                  </div>
                  <div class="max-sm:border-b">
                    <p class="font-bold">Bill To:</p>
                    <p>{props.BillTo.Recipient}</p>
                    <p>
                      {props.BillTo.Number} {props.BillTo.Street}
                    </p>
                    <p>{props.BillTo.Street2}</p>
                    <p>
                      {props.BillTo.City}, {props.BillTo.Region}
                    </p>
                    <p>
                      {props.BillTo.Country}, {props.BillTo.PostalCode}
                    </p>
                    <Show when={props.BillTo.Phone}>
                      <p>Tel: {props.BillTo.Phone}</p>
                    </Show>
                    <Show when={props.BillTo.Email}>
                      <p>Email: {props.BillTo.Email}</p>
                    </Show>
                  </div>
                  <div>
                    <p class="font-bold">BPS:</p>
                    <p>{props?.BPSName === "" ? "N/A" : props.BPSName}</p>
                    <Show when={!!props.BPSName && props.BPSName !== ""}>
                      <p>
                        {props?.BPSNumber === "" ? "" : `(${props.BPSNumber})`}
                      </p>
                    </Show>
                    <p class="font-bold">
                      Created:{" "}
                      <span class="font-normal">
                        {displayDate(Date.parse(props.CreatedAt))},{" "}
                        {displayTime(Date.parse(props.CreatedAt))}
                      </span>
                    </p>
                    <p class="font-bold">
                      Line Count:{" "}
                      <span class="font-normal">{props.Lines.length}</span>
                    </p>
                    <p class="font-bold">
                      Amount: <span class="font-normal">${props.Amount}</span>
                    </p>
                  </div>
                </div>
                <p class="font-bold text-base pb-2">ORDER ITEMS:</p>
                <For each={props.Lines}>
                  {(line) => {
                    if (
                      props.OrderType === "Custom" ||
                      props.OrderType === "General"
                    ) {
                      return (
                        <div class="border-t py-3 grid sm:grid-cols-3">
                          <ul class="max-sm:px-4 max-sm:py-2">
                            <li>
                              <span class="font-bold text-sm">ID:</span>{" "}
                              {line.ID}
                            </li>
                            <li>
                              <span class="font-bold text-sm">SKU:</span>{" "}
                              {line.SKU ? line.SKU : line?.SubItems?.[0]?.SKU}
                            </li>
                            <li>
                              <span class="font-bold text-sm">TYPE:</span>{" "}
                              {line.Type}
                            </li>
                            <li>
                              <span class="font-bold text-sm">QTY:</span>{" "}
                              {line.Quantity}
                            </li>
                            <li>
                              <span class="font-bold text-sm">AMOUNT:</span> $
                              {line.Amount ? line.Amount.toFixed(2) : ""}
                            </li>
                          </ul>
                          <div class="bg-roma-grey grow py-3 px-4 sm:col-span-2 flex flex-col gap-4">
                            <Show when={line.NotesExternal}>
                              <div>
                                <p class="font-bold text-base">
                                  External Notes
                                </p>
                                <pre
                                  class="ml-1 font-sans whitespace-pre-line"
                                  innerHTML={URLify(line.NotesExternal)}
                                />
                              </div>
                            </Show>
                            <Show when={line.NotesInternal}>
                              <div>
                                <p class="font-bold text-base">
                                  Internal Notes
                                </p>
                                <pre
                                  class="ml-1 font-sans whitespace-pre-line"
                                  innerHTML={URLify(line.NotesInternal)}
                                />
                              </div>
                            </Show>
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div class="py-2 border-t flex items-center ">
                        <div class="basis-[40px] grow-0">
                          <LineImage size="xs" {...line} line={line} />
                        </div>
                        <LineAttributes line={line} />
                      </div>
                    );
                  }}
                </For>
              </div>
            </div>
          </td>
        </tr>
      </>
    );
  };

  const FilterItem: Component<{
    status: ORDERSTATUS;
  }> = (props) => {
    // *modified, test
    const toggleFilter = (option: ORDERSTATUS) => {
      if (filters().status) {
        let current = new Set(filters().status);
        if (current.has(option)) {
          current.delete(option);
        } else {
          current.add(option);
        }
        setFilters((prev) => ({ ...prev, status: Array.from(current) }));
      } else {
        setFilters((prev) => ({ ...prev, status: [option] }));
      }
    };

    const isActive = createMemo(() => {
      if (!filters().status) return false;
      let current = new Set(filters().status);
      if (current.has(props.status)) {
        return true;
      }
      return false;
    });

    return (
      <div
        class="flex items-center cursor-pointer group"
        onClick={() => {
          toggleFilter(props.status);
        }}
      >
        <div
          class={` w-4 h-4 rounded-full bg-${
            statusDictionary[props.status][0]
          } flex items-center justify-center shrink-0`}
        >
          <div
            class=" w-2 h-2 rounded-full shrink-0"
            classList={{
              [`bg-${statusDictionary[props.status][0]}`]: isActive(),
              "bg-white": !isActive(),
            }}
          />
        </div>
        <span
          class={`ml-1 text-sm ${
            isActive() ? "text-roma-dark-grey" : "text-roma-medium-grey"
          } group-hover:text-roma-dark-grey`}
        >
          {statusDictionary[props.status][1]}
        </span>
      </div>
    );
  };

  const Pagination = () => {
    const [currentPage, setCurrentPage] = createSignal<number>(0);

    return (
      <div class="flex items-stretch border border-gray-300 text-sm">
        <p class="w-full grow-1 px-5 py-2 whitespace-nowrap">
          {(filters().offset ?? 0) + 1}-
          {(filters().offset ?? 0) + (filters().limit ?? 0) >
          (orders.latest?.Total ?? 0)
            ? orders.latest?.Total
            : (filters().offset ?? 0) + (filters().limit ?? 0)}
          &nbsp;of&nbsp;{orders.latest?.Total}
        </p>
        <div class="flex">
          <button
            aria-label="Previous"
            class="border-l flex items-center justify-center p-2 disabled:text-roma-medium-grey disabled:cursor-not-allowed"
            onClick={() => {
              setCurrentPage((n: number) => (n -= 1));
              setFilters((prev) => ({
                ...prev,
                offset: currentPage() * filters().limit!,
              }));
            }}
            disabled={currentPage() == 0}
          >
            <Icon path={chevronLeft} class="w-4 h-4" />
          </button>
          <button
            aria-label="Next"
            class="border-l flex items-center justify-center p-2 disabled:text-roma-medium-grey disabled:cursor-not-allowed"
            onClick={() => {
              setCurrentPage((n) => (n += 1));
              setFilters((prev) => ({
                ...prev,
                offset: currentPage() * filters().limit!,
              }));
            }}
            disabled={
              (filters()?.offset ?? 0) + (filters()?.limit ?? 0) >=
              orders.latest!.Total
            }
          >
            <Icon path={chevronRight} class="w-4 h-4" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <AccountPanel>
      <AuthBarrier
        permissions={["APPROVESAMPLES", "BPSAPPROVEORDERS"]}
        requireAny
      >
        <div class="max-w-6xl">
          <div class="flex max-sm:flex-col sm:items-baseline justify-between -mb-3">
            <h3 class="text-3xl font-bold">
              {permission.BPSAPPROVEORDERS ? "BPS " : ""}
              Orders
            </h3>
            <div class="grid grid-cols-[auto_min-content] max-sm:pt-2 items-center">
              <div>
                <Checkbox
                  label="Auto-update (5 mins)"
                  rootClass="mt-1 mr-2 whitespace-nowrap"
                  onChange={setEnableAutoUpdate}
                  checked={enableAutoUpdate()}
                />
              </div>
              <ActionButton
                class="font-medium gap-2"
                label="Refresh"
                onClick={refreshOrders}
                icon={arrowPathRoundedSquare}
              />
              <p class="row-start-2 col-span-full text-right text-roma-medium-grey py-1 text-xs">
                Last updated at{" "}
                {displayTime(Date.parse(polledLastUpdate().toISOString()))}
              </p>
            </div>
          </div>
          <ErrorBoundary
            fallback={(error, reset) => {
              const err = checkError(error);
              // HERE
              // addError(err, {severity: "warning", title: "Order Retrieval Error"})
              return (
                <GenericError
                  error={err}
                  reset={reset}
                  class="mt-4  rounded-md"
                />
              );
            }}
          >
            <Suspense
              fallback={
                <div class="flex flex-col gap-3 mt-8">
                  <BaseSkeleton height={50} class="mb-10" />
                  <For each={new Array(10)}>
                    {(_, i) => <BaseSkeleton height={25} delay={i() * 200} />}
                  </For>
                </div>
              }
            >
              <div class="mt-6">
                <Show when={permission.BPSAPPROVEORDERS}>
                  <p class="text-roma-dark-grey pb-2 text-sm">
                    Note: Customer orders below reflect web-orders only
                  </p>
                  <CustomerSearch
                    onChange={(arr: ComboOption[]) => {
                      setFilters((prev) => ({
                        ...prev,
                        customers:
                          arr.length > 0
                            ? (arr as ComboOption[]).map((obj) => obj.value)
                            : "all",
                      }));
                    }}
                    onClear={() => {
                      setFilters((prev) => ({ ...prev, customers: "all" }));
                    }}
                    multiple={true}
                    style="square"
                  />
                </Show>
                <div class="grid sm:grid-cols-[2fr_1fr_1fr] items-end gap-2">
                  <div>
                    <p class="text-sm text-roma-dark-grey mb-2 mt-1">
                      Order Types:
                    </p>
                    <MultiSelect
                      class="rounded-none"
                      options={Object.keys(orderTypesDict)}
                      // @ts-expect-error
                      onChange={(arr: string[]) => {
                        const formatted = arr.map(
                          (item) => orderTypesDict[item]
                        ) as ORDERTYPE[];
                        setFilters((prev) => ({ ...prev, type: formatted }));
                      }}
                      // value={orderTypes().map((item) => orderTypesReverse[item])}
                      value={filters().type?.map(
                        (item) => orderTypesReverse[item]
                      )}
                      hideSelectedPills
                      selectedMessage={(n) => `${n} Types Selected`}
                    />
                  </div>
                  <div>
                    <Input
                      label="Start Date:"
                      name="start-date"
                      type="date"
                      labelClass="text-roma-dark-grey font-normal"
                      class="!rounded-none !py-[0.45rem] text-roma-dark-grey text-sm"
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          from: e.target.value,
                        }))
                      }
                      max={filters().to}
                    />
                  </div>
                  <div>
                    <Input
                      label="End Date:"
                      name="end-date"
                      type="date"
                      value={filters().to}
                      labelClass="text-roma-dark-grey font-normal"
                      class="!rounded-none !py-[0.45rem] text-roma-dark-grey text-sm"
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          to: e.target.value,
                        }))
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </div>
                </div>
                <div class="flex flex-col md:flex-row justify-between md:items-center gap-2 mt-2  ">
                  <TextFieldInput
                    name="search"
                    class="rounded-sm"
                    rootClass=" basis-full grow-1"
                    placeholder="Search by customer number, SAP order, account ID"
                    onChange={(val) => doSearch(val.trim())}
                  />
                  {/* HERE1 */}
                  <div class="flex flex-col md:flex-row md:items-center gap-2">
                    <SelectBox
                      options={[
                        { label: "Order ID", value: "order_id" },
                        { label: "Date Created", value: "created_at" },
                        { label: "Date Submitted", value: "submitted_at" },
                        { label: "Order Status", value: "status" },
                        {
                          label: "Customer Number",
                          value: "customer_number",
                        },
                      ]}
                      defaultValue={filters().sort}
                      onChange={(option) => {
                        setFilters((prev) => ({
                          ...prev,
                          sort: option.value as ORDERSORT,
                        }));
                      }}
                      inlineTitle="Sort:"
                      triggerClass="whitespace-nowrap rounded-sm"
                    />
                    <SelectBox
                      options={[
                        { label: "10", value: 10 },
                        { label: "25", value: 25 },
                        { label: "50", value: 50 },
                        { label: "100", value: 100 },
                        { label: "150", value: 150 },
                        { label: "200", value: 200 },
                      ]}
                      onChange={(option) => {
                        // setLimit(option.value as number);
                        setFilters((prev) => ({
                          ...prev,
                          limit: option.value as number,
                        }));
                      }}
                      defaultValue={filters().limit}
                      inlineTitle="Show:"
                      triggerClass="rounded-sm whitespace-nowrap"
                    />
                    <Show
                      when={
                        orders.latest &&
                        orders.latest?.Total! > filters().limit!
                      }
                    >
                      <Pagination />
                    </Show>
                  </div>
                </div>
                <div class="flex items-center mt-3 ">
                  <div class="flex flex-row max-md:flex-wrap text-sm gap-2 border border-gray-300 md:pr-3 max-md:p-2">
                    <p class="text-sm max-md:w-full py-2 px-3 md:border-r  max-md:border-b  border-gray-300 font-medium bg-roma-grey text-roma-dark-grey">
                      Status:
                    </p>
                    <For each={Object.keys(statusDictionary)}>
                      {(item) => <FilterItem status={item as ORDERSTATUS} />}
                    </For>
                  </div>
                  <Show
                    when={
                      Array.isArray(filters().status) &&
                      filters()!.status!.length > 0
                    }
                  >
                    <button
                      onClick={() => {
                        setFilters((prev) => ({ ...prev, status: [] }));
                      }}
                      class="text-roma-blue text-xs ml-4"
                    >
                      Clear
                    </button>
                  </Show>
                </div>
                <Show when={orders.latest}>
                  <div class="-mx-4 mt-4 mb-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-sm">
                    <table class="min-w-full divide-y divide-gray-300 ">
                      <thead class="bg-gray-50 ">
                        <tr>
                          <th
                            scope="col"
                            class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Order
                          </th>
                          <th
                            scope="col"
                            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Customer
                          </th>
                          <th
                            scope="col"
                            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            class="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                          >
                            Date
                          </th>
                          <th
                            scope="col"
                            class="hidden px-3 py-3.5 text-center text-sm font-semibold text-gray-900 lg:table-cell "
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            class="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span class="sr-only">Action</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="divide-y divide-gray-200 bg-white">
                        <For
                          each={orders.latest?.Results}
                          fallback={
                            <tr>
                              <td colspan="100%" class="w-full table-cell">
                                <p class="text-center py-10">
                                  No results found
                                </p>
                              </td>
                            </tr>
                          }
                        >
                          {(order, index) => (
                            <OrderLine {...order} index={index()} />
                          )}
                        </For>
                      </tbody>
                    </table>
                  </div>
                </Show>
              </div>{" "}
              {Prompt}
            </Suspense>
          </ErrorBoundary>
        </div>
      </AuthBarrier>
    </AccountPanel>
  );
}
