import { fetchAPI2 } from "../../fetchAPI2";
import { simulateApiError } from "../../errors";

export type OrderResubmitLine = { ID: string };

export type OrderResubmitPayload = OrderResubmitLine[];

export const _sendOrderResubmission = async (
  submission: OrderResubmitLine | OrderResubmitLine[]
) => {
  const payload = Array.isArray(submission) ? submission : [submission];

  // await simulateApiError({path: "/admin/orders/resubmit"});

  const data = await fetchAPI2<unknown, OrderResubmitPayload>(
    "/admin/orders/resubmit",
    {
      method: "POST",
      body: payload,
    }
  );

  return data;
};
