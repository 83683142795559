import { query, createAsync } from "@solidjs/router";
import { _listAdminOrders, type OrderFilters } from "../roma-api/admin/orders";

export const getAdminOrders = query(
  async (filters?: Partial<OrderFilters>) => _listAdminOrders(filters),
  "admin-orders"
);

export const useAdminOrders = (
  filters: ()=>Partial<OrderFilters> = ()=>({}),
  options: { deferStream: boolean } = { deferStream: false }
) => createAsync(() => getAdminOrders(filters()), options);
